<template>
  <div v-if="selectedChatroom" class="sidebox pt-3">
    <!-- 顯示圖片的 modal -->
    <b-modal id="modal-showImg" ok-title="關閉" centered ok-only>
      <img style="width: 480px" :src="currentImg" />
    </b-modal>

    <!-- 自訂暱稱的 modal -->
    <b-modal
      id="modal-nickname"
      title="自訂暱稱"
      ok-title="儲存"
      cancel-title="取消"
      centered
      @ok="handleUpdateCustomerProfile"
      @hidden="handleCloseUpdateNickname"
    >
      <b-input
        type="text"
        class="form-control"
        maxlength="20"
        placeholder="自訂暱稱不會向對方公開"
        v-model="inputNickname"
      />
    </b-modal>

    <!-- 變更姓名的 modal -->
    <b-modal
      id="modal-name"
      title="變更姓名"
      ok-title="儲存"
      cancel-title="取消"
      centered
      @ok="handleUpdateCustomerProfile"
    >
      <b-input
        type="text"
        class="form-control"
        maxlength="20"
        placeholder="變更姓名"
        v-model="customerProfile.name"
      />
    </b-modal>

    <!-- 變更身分證字號的 modal -->
    <b-modal
      id="modal-personal-id"
      title="變更身份證字號"
      ok-title="儲存"
      cancel-title="取消"
      centered
      @ok="handleUpdateCustomerProfile"
    >
      <b-input
        type="text"
        class="form-control"
        maxlength="20"
        placeholder="變更身份證字號"
        v-model="customerProfile.personal_id"
      />
    </b-modal>

    <!-- 編輯標籤的 modal -->
    <b-modal
      id="modal-tags"
      title="編輯標籤"
      ok-title="儲存"
      cancel-title="取消"
      centered
      @ok="handleSaveTags"
    >
      <div class="border rounded p-1">
        <b-badge
          v-for="(tag, index) in tags"
          :key="tag.id"
          variant="dark"
          class="m-1"
        >
          {{ tag.name }}
          <button class="btn btn-xs p-0" @click="handleClickRemoveTag(index)">
            <i class="fa fa-remove text-white" />
          </button>
        </b-badge>
        <b-input
          type="text"
          class="border-0"
          maxlength="40"
          placeholder="輸入標籤"
          v-model="inputTag"
          @keyup.enter="handleEnterInputTag"
          @keyup="handleKeyUpInputTag"
          @blur="handleEnterInputTag"
        />
      </div>

      <template v-if="false">
        <div class="h6 mt-3">現有標籤</div>
        <b-button
          v-show="false"
          v-for="i in tags"
          :key="i"
          variant="secondary"
          class="m-1 text-dark"
          size="xs"
        >
          {{ i }}
        </b-button>
      </template>
    </b-modal>

    <!-- 自訂筆記 modal -->
    <b-modal
      id="modal-note"
      title="自訂筆記"
      ok-title="儲存"
      cancel-title="取消"
      centered
      @ok="handleSaveNote"
    >
      <b-textarea
        class="form-control"
        maxlength="300"
        placeholder="你可於此新增用戶的相關資訊，且記事本中的資訊不會向對方公開"
        rows="10"
        v-model="customerProfile.note"
      />
    </b-modal>

    <!-- 成員&檔案 分頁 section -->

      <b-tabs
        class="h-100 d-flex flex-column overflow-y-hidden"
        v-if="showMemberListInDashboard || showFileListInDashboard"
        content-class="flex-fill overflow-y-auto overflow-x-hidden pl-2"
      >
        <b-tab
          title="成員"
          title-item-class="px-2"
          v-if="showMemberListInDashboard"
        >
          <b-list-group v-if="!selectedMember">
            <div class="text-center mt-3 mb-3 loading-spinner" v-if="loading">
              <b-spinner variant="secondary" small />
            </div>
            <div v-else>
              <ChatroomAddMember
                v-if="canShowAddMemberButton"
                :chatroom="selectedChatroom"
                :disabled="disabledAddMemberButton"
                @added="
                  fetchCustomers();
                  fetchStaffs();
                "
              ></ChatroomAddMember>

              <b-list-group-item
                class="d-flex align-items-center border-0"
                v-for="staff in staffs"
                :key="staff.id"
                href="#"
              >
                <b-avatar class="mr-1" :src="staff.avatar_url" :class="staff.is_enabled ? '' : 'gray-mask'"></b-avatar>
                <div
                  class="mr-auto font-weight-bold text-nowrap text-truncate pr-2"
                >
                  {{ staff.name }}
                </div>

                <div class="text-nowrap">
                  <b-button
                    v-if="
                      chatroomConfig.invite_staff_in_dashboard_permission_role &&
                      canShowRemoveMemberButton &&
                      (staff.role !== chatroomConfig.invite_staff_in_dashboard_permission_role)
                    "
                    size="xs"
                    pill
                    variant="danger"
                    class="p-1 mr-2"
                    @click="removeMember(staff)"
                  >
                    <i class="fa fa-times m-0"></i>
                  </b-button>
                </div>

                <!-- <i class="fa fa-angle-right" aria-hidden="true"></i> -->
              </b-list-group-item>

              <b-list-group-item
                class="d-flex align-items-center border-0"
                v-for="customer in customers"
                :key="customer.id"
                href="#"
                @click="handleSelectMember(customer.id)"
              >
                <b-avatar class="mr-1" :src="customer.avatar_url" :class="customer.is_enabled ? '' : 'gray-mask'"></b-avatar>
                <div
                  class="mr-auto font-weight-bold text-nowrap text-truncate pr-2"
                >
                  {{ customer.name }}
                </div>

                <div class="text-nowrap">
                  <!--移除客戶的功能暫時還不開放-->
                  <!-- <b-button size="xs" pill variant="danger" class="p-1 mr-2" @click="removeMember(customer)">
                    <i class="fa fa-times m-0"></i>
                  </b-button> -->
                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                </div>
              </b-list-group-item>
            </div>
          </b-list-group>

          <div class="profile" v-if="selectedMember">
            <button class="btn btn-link" @click="selectedMember = false">
              <i class="fa fa-angle-left" aria-hidden="true"></i> 回列表
            </button>
            <div class="text-center p-3">
              <b-avatar
                class="my-4"
                size="8rem"
                :src="customer.avatar_url"
              ></b-avatar>
              <div class="h4 p-2 text-left">
                <h6 class="mb-2">LINE 名稱：{{ customer.name }}</h6>
                <h6 class="m-0">
                  自訂暱稱：{{ customerProfile.nickname
                  }}<button
                    class="btn m-0"
                    v-b-modal.modal-nickname
                    :hidden="
                      !$permissions.has(
                        $permissions.consts
                          .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                      )
                    "
                  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </h6>
                <h6 class="m-0">
                  姓名：{{ customerProfile.name
                  }}<button
                    class="btn m-0"
                    v-b-modal.modal-name
                    :hidden="
                      !$permissions.has(
                        $permissions.consts
                          .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                      )
                    "
                  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </h6>
                <h6 class="mb-2">
                  身份證字號：{{ customerProfile.personal_id
                  }}<button
                    class="btn m-0"
                    v-b-modal.modal-personal-id
                    :hidden="
                      !$permissions.has(
                        $permissions.consts
                          .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                      )
                    "
                  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </h6>
                <div class="tags">
                  <h6 class="m-0">標籤：</h6>
                  <b-badge
                    v-for="tag in tagsOriginal"
                    :key="tag.id"
                    variant="dark"
                    class="m-1"
                    >{{ tag.name }}</b-badge
                  >
                  <button
                    class="btn"
                    v-b-modal.modal-tags
                    :hidden="
                      !$permissions.has(
                        $permissions.consts
                          .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                      )
                    "
                  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>

            <b-list-group
              id="accordionExample"
              flush
              class="border-top border-bottom accordion"
            >
              <b-list-group-item role="button">
                <div
                  v-b-toggle.collapse-note
                  class="d-flex justify-content-between align-items-center"
                >
                  自訂筆記
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
                <b-collapse id="collapse-note" class="py-3">
                  <div
                    style="white-space: break-spaces"
                    v-html="customerProfile.note"
                  ></div>
                  <button
                    class="btn"
                    v-b-modal.modal-note
                    :hidden="
                      !$permissions.has(
                        $permissions.consts
                          .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                      )
                    "
                  >
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </b-collapse>
              </b-list-group-item>
              <b-list-group-item role="button">
                <div
                  v-b-toggle.collapse-profile
                  class="d-flex justify-content-between align-items-center"
                >
                  基本資料
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
                <b-collapse id="collapse-profile" class="py-3">
                  <div class="form-group">
                    <label for="InputPhone">行動電話</label>
                    <input
                      type="tel"
                      class="form-control"
                      id="InputPhone"
                      placeholder="請使用 0-9,+,-組合的電話號碼格式"
                      v-model="customer.mobile_phone"
                      :disabled="
                        !$permissions.has(
                          $permissions.consts
                            .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                        )
                      "
                    />
                  </div>

                  <div class="form-group">
                    <label for="InputEmail">電子信箱</label>
                    <input
                      type="email"
                      class="form-control"
                      id="InputEmail"
                      placeholder="請輸入電子信箱"
                      v-model="customer.email"
                      :disabled="
                        !$permissions.has(
                          $permissions.consts
                            .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                        )
                      "
                    />
                  </div>

                  <div class="form-group">
                    <label for="InputBirthday">生日</label>
                    <input
                      type="date"
                      class="form-control"
                      id="InputBirthday"
                      placeholder="日期格式為西元年月日，如 1990-01-01"
                      v-model="customer.birthday"
                      :disabled="
                        !$permissions.has(
                          $permissions.consts
                            .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                        )
                      "
                    />
                  </div>

                  <div class="form-group">
                    <label for="InputGender">性別</label>
                    <b-form-radio-group
                      v-model="customer.sexual"
                      :options="sexualOptions"
                      :disabled="!$permissions.has(
                          $permissions.consts
                            .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                        )"
                      class="mb-3"
                      value-field="value"
                      text-field="text"
                    ></b-form-radio-group>
                  </div>

                  <div class="form-group" v-if="false">
                    <label for="InputLanguage">語言</label>
                    <input
                      type="text"
                      class="form-control"
                      id="InputLanguage"
                      placeholder="語言"
                      v-model="customer.language"
                      :disabled="
                        !$permissions.has(
                          $permissions.consts
                            .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                        )
                      "
                    />
                  </div>

                  <div class="form-group" v-if="false">
                    <label for="InputCountry">國家</label>
                    <input
                      type="text"
                      class="form-control"
                      id="InputCountry"
                      placeholder="國家"
                      :disabled="
                        !$permissions.has(
                          $permissions.consts
                            .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                        )
                      "
                    />
                  </div>

                  <div class="form-group" v-if="false">
                    <label for="InputCity">現居城市</label>
                    <input
                      type="text"
                      class="form-control"
                      id="InputCity"
                      placeholder="現居城市"
                      :disabled="
                        !$permissions.has(
                          $permissions.consts
                            .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                        )
                      "
                    />
                  </div>

                  <button
                    class="btn btn-success"
                    @click="handleClickSaveCustomer"
                    :hidden="
                      !$permissions.has(
                        $permissions.consts
                          .CHATROOM_LIST_CHAT_MODIFY_CUSTOMER_PROFILE
                      )
                    "
                  >
                    儲存
                  </button>
                </b-collapse>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-tab>
        <b-tab
          title="檔案"
          title-item-class="px-2"
          @click="fetchFileList"
          v-if="showFileListInDashboard"
        >
          <b-list-group>
            <div class="text-center mt-3 mb-3 loading-spinner" v-if="loading">
              <b-spinner variant="secondary" small />
            </div>
            <div v-else>
              <b-list-group-item
                class="d-flex align-items-center border-0"
                v-for="(file, i) in fileList"
                :key="i"
                :href="catFileType(file.mime) === 'jpg'? '': file.url"
              >
                <img
                  v-if="catFileType(file.mime) === 'csv'"
                  class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/csv.png"
                >
                <img
                  v-if="catFileType(file.mime) === 'doc'"
                  class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/doc.png"
                >
                <img
                  v-if="catFileType(file.mime) === 'file'"
                  class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/file.png"
                >
                <img
                  v-if="catFileType(file.mime) === 'jpg'"
                  class="mr-2 file-type-icon" :src="file.url"
                  @click="currentImg = file.url"
                  v-b-modal.modal-showImg
                >
                <img
                  v-if="catFileType(file.mime) === 'pdf'"
                  class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/pdf.png"
                >
                <img
                  v-if="catFileType(file.mime) === 'ppt'"
                  class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/ppt.png"
                >
                <img
                  v-if="catFileType(file.mime) === 'txt'"
                  class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/txt.png"
                >
                <img
                  v-if="catFileType(file.mime) === 'xls'"
                  class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/xls.png"
                >
                <img
                  v-if="catFileType(file.mime) === 'zip'"
                  class="mr-2 file-type-icon" src="@/assets/images/file_type_icons/zip.png"
                >

                <div class="d-flex flex-column lh-sm-0 text-truncate">
                  <div class="mr-auto font-weight-bold">
                    {{ file.name }}
                  </div>
                  <div class="small text-muted">{{ file.sender.name }}</div>
                  <div class="small text-muted">{{ formatDateD(file.created_at) }}</div>
                </div>
              </b-list-group-item>
            </div>
          </b-list-group>
          <b-pagination
            class="separated mb-md-0 align-self-center py-3"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            @change="handleChangePage"
          ></b-pagination>
        </b-tab>
      </b-tabs>
    </div>

</template>

<script>
import ChatroomAddMember from "@/pages/Dashboard/Chatrooms/ChatroomAddMember.vue";
import chatroomApi from "@/apis/chatrooms";
import customerApi from "@/apis/customer";
import store from "@/store";
import format from "date-fns/format";

export default {
  components: {
    ChatroomAddMember,
  },
  props: {
    selectedChatroom: {
      type: Object,
    },
    chatroomConfig: {
      type: Object,
    },
    showMemberListInDashboard: {
      type: Boolean,
    },
    showFileListInDashboard: {
      type: Boolean,
    },
  },
  watch: {
    async selectedChatroom() {
      if (!this.selectedChatroom?.id) return
      this.fetchAll()
    },
  },
  data() {
    return {
      // data
      staffs: [],
      customers: [],
      selectedMember: false,
      fileList: [],
      tags: ["買香水", "女性", "20歲", "永和", "嬰兒用品"],
      tagsOriginal: [],
      customerProfile: {},
      inputNickname: null,
      inputTag: null,
      customer: {},
      currentImg: "",
      sexualOptions: [
        { text: '男', value: 'male' },
        { text: '女', value: 'female' },
        { text: '未設定', value: null },
      ],

      // flags
      loading: false,

      // files pagination
      currentPage: 1,
      total: 0,
      perPage: 10,
    }
  },
  computed: {
    staffId() {
      return store.state.auth.user.id;
    },
    canShowAddMemberButton() {
      if (!this.chatroomConfig) return false;
      const sender = this.selectedChatroom?.senders.find(
        (s) => s.id === this.staffId
      );
      if (!sender) return false;
      return (
        this.chatroomConfig.invite_staff_in_dashboard_permission_role === sender.role &&
        (this.chatroomConfig.allow_invite_staff_in_dashboard ?? false)
      );
    },
    // 是否 disabled 加入成員的按鈕
    disabledAddMemberButton() {
      if (!this.chatroomConfig) return true;

      const matchRoles = this.selectedChatroom?.senders.filter(
        (s) => s.role === this.chatroomConfig.staff_invite_default_role
      );

      // 如果符合 default_role 的人數大於等於最高邀請上限，則 disabled
      return matchRoles.length >= this.chatroomConfig.staff_invite_max_count
    },
    canShowRemoveMemberButton() {
      if (!this.chatroomConfig) return false;

      const sender = this.selectedChatroom?.senders.find(
        (s) => s.id === this.staffId
      );

      if (!sender) return false;

      return (
        this.chatroomConfig.invite_staff_in_dashboard_permission_role === sender.role &&
        (this.chatroomConfig.allow_invite_staff_in_dashboard ?? false)
      );
    },
  },
  methods: {
    async fetchAll() {
      this.loading = true

      await Promise.all([
        this.fetchFileList(),
        this.fetchCustomers(),
        this.fetchStaffs(),
        this.resetCurrentCustomer(),
      ])

      this.loading = false
    },
    handleChangePage() {
      this.$nextTick(() => {
        this.fetchFileList();
      });
    },
    async fetchFileList() {
      try {
        let params = {
          page: this.currentPage,
          per_page: this.perPage,
        };
        const { data } = await chatroomApi.getFileList({
          chatroom: this.selectedChatroom,
          params: params,
        });
        this.fileList = data.data;
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
      } catch (e) {
        console.error(e);
      }
    },
    catFileType(fileType) {
      let type = "file";

      const jpegTypes = ["jpeg", "jpg", "png"];
      jpegTypes.forEach((jpegType) => {
        fileType.includes(jpegType) ? (type = "jpg") : "";
      });

      const docTypes = ["doc", "docx"];
      docTypes.forEach((docType) => {
        fileType.includes(docType) ? (type = "doc") : "";
      });

      const pdfTypes = ["pdf"];
      pdfTypes.forEach((pdfType) => {
        fileType.includes(pdfType) ? (type = "pdf") : "";
      });

      const csvTypes = ["csv"];
      csvTypes.forEach((csvType) => {
        fileType.includes(csvType) ? (type = "csv") : "";
      });

      const pptTypes = ["ppt", "pptx"];
      pptTypes.forEach((pptType) => {
        fileType.includes(pptType) ? (type = "ppt") : "";
      });

      const txtTypes = ["txt"];
      txtTypes.forEach((txtType) => {
        fileType.includes(txtType) ? (type = "txt") : "";
      });

      const xlsTypes = ["xls", "xlsx"];
      xlsTypes.forEach((xlsType) => {
        fileType.includes(xlsType) ? (type = "xls") : "";
      });

      const zipTypes = ["zip"];
      zipTypes.forEach((zipType) => {
        fileType.includes(zipType) ? (type = "zip") : "";
      });

      return type;
    },
    formatDateD(value) {
      if (value) {
        return format(new Date(value), 'yyyy-MM-dd HH:mm');
      }
    },
    async fetchCustomers() {
      const { data } = await chatroomApi.getCustomers(this.selectedChatroom);
      this.customers = data.data;
    },
    async fetchStaffs() {
      const { data } = await chatroomApi.getStaffs(this.selectedChatroom);
      this.staffs = data.data;
    },
    async removeMember(member) {
      let confirm = await this.$swal.fire({
        type: "warning",
        title: "從聊天室移除",
        text: "您確認要將此成員自聊天室移除嗎？",
        confirmButtonText: "確認",
        cancelButtonText: "取消",
        showCancelButton: true,
      })

      if (!confirm.value) return

      try {
        await chatroomApi.removeMember({
          chatroom: this.selectedChatroom,
          params: {
            staff_id: member.id,
          }
        });

        this.$swal.fire("移除成員", "成功將成員自聊天室中移除", "success");
        this.fetchCustomers();
        this.fetchStaffs();
      } catch (error) {
        console.error(error);
        let message = "失敗";

        if (error.response.data.message) {
          message = error.response.data.message;
        }

        this.$swal.fire("移除成員", message, "danger");
      }
    },
    handleSelectMember(customerId) {
      const me = this;
      const promise1 = this.fetchCustomer(customerId);
      const promise2 = this.fetchCustomerProfile(customerId);
      this.fetchCustomerTags(customerId);
      Promise.all([promise1, promise2]).then(function () {
        if (!me.customerProfile.nickname) {
          me.customerProfile.nickname = me.customer.name;
        }
        me.inputNickname = me.customerProfile.nickname;
        me.$forceUpdate();
      });
    },

    async fetchCustomer(customerId) {
      const { data } = await customerApi.getCustomer(customerId);
      this.customer = data;
      this.customer.language = this.customer.data?.language;
      this.selectedMember = true;
    },

    async fetchCustomerProfile(customerId) {
      const { data } = await customerApi.getCustomerProfiles(customerId);
      this.customerProfile = data.data;
    },

    async fetchCustomerTags(customerId) {
      const { data } = await customerApi.getCustomerTags(customerId);
      this.tagsOriginal = data.data;
      this.tags = Array.from(data.data);
    },

    resetCurrentCustomer() {
      this.selectedMember = false
      this.customer = null;
      this.customerProfile.nickname = null;
      this.customerProfile.name = null;
      this.customerProfile.personal_id = null;
      this.customerProfile.note = null;
      this.inputTag = null;
      this.inputNickname = null;
    },

    async handleUpdateCustomerProfile() {
      await customerApi.storeCustomerProfiles(this.customer.id, {
        ...this.customerProfile,
        nickname: this.inputNickname,
      });

      await this.fetchCustomerProfile(this.customer.id);
      // await this.fetchCustomers() //看有沒有要及時改顯示留言再決定要打開與否
      this.inputNickname = this.customerProfile.nickname;
      this.$nextTick(() => {
        this.$emit("updateMessageNickname", {
          sender_id: this.customer.id,
          nickname: this.inputNickname,
        });
      });
    },

    handleCloseUpdateNickname() {
      this.inputNickname = this.customerProfile.nickname;
    },

    async handleSaveTags() {
      let payload = {
        tags: this.tags,
      };

      await customerApi.setCustomerTags(this.customer.id, payload);
      this.fetchCustomerTags(this.customer.id);
    },

    handleClickRemoveTag(index) {
      this.tags.splice(index, 1);
    },

    handleEnterInputTag() {
      if (!this.inputTag) {
        return;
      }

      let isExisted = false;
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].name == this.inputTag) {
          isExisted = true;
          break;
        }
      }

      if (isExisted) {
        this.inputTag = null;
        return;
      }

      this.tags.push({
        name: this.inputTag,
      });

      this.inputTag = null;
    },
    // 逗號也要當作tag 分隔
    handleKeyUpInputTag(event) {
      if (event.key == ",") {
        this.inputTag = this.inputTag.slice(0, -1);
        this.handleEnterInputTag();
      }
    },

    async handleSaveNote() {
      await customerApi.storeCustomerProfiles(this.customer.id, {
        note: this.customerProfile.note,
      });
    },

    async handleClickSaveCustomer() {
      let params = {
        mobile_phone: this.customer.mobile_phone,
        email: this.customer.email,
        birthday: this.customer.birthday,
        sexual: this.customer.sexual,
      };

      try {
        await customerApi.storeCustomer(this.customer.id, params);
        this.$swal.fire({
          icon: "success",
          text: "儲存成功",
        });
      } catch (err) {
        let errWording = "";
        for (let errKey in err.response.data.message) {
          errWording += err.response.data.message[errKey].join(",");
        }
        this.$swal("失敗", errWording, "error");
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.gray-mask {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
}
</style>
