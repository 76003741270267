<template>
  <div :id="`message-${message.id}`" :class="['message-box', 'my-2', isSender(message) && 'owner']">
    <div
      class="strike small text-muted pb-2"
      v-if="firstMessage && firstMessage.id === message.id && firstLoad"
    >
      對話開始
    </div>
    <div
      class="strike small text-muted pb-2"
      v-else-if="firstNewMessage && firstNewMessage.id === message.id"
    >
      最新訊息
    </div>
    <div
      class="strike small text-muted pb-2"
      v-else-if="firstUnreadMessage && firstUnreadMessage.id === message.id"
    >
      未讀
    </div>
    <div
      class="strike small text-muted pb-2"
      v-if="checkStrike()"
    >
      {{ formatDateD }}
    </div>
    <div class="message-wrapper">
      <div class="d-flex align-items-start">
        <div>
          <b-avatar class="mr-2" :src="message.sender.avatar_url"></b-avatar>
          <small class="mb-1">
            {{ messageSenderName }}
          </small>
          <div></div>
          <template
            v-if="message.type === 'image' && message.meta.images.length > 0"
          >
            <div
              class="message image"
              :class="{
                multiple: message.meta.images && message.meta.images.length > 1,
              }"
            >
              <div
                class="image-wrapper"
                :class="{ expanded: message.meta.images.length & 1 && i === 0 }"
                :key="i"
                v-for="(image, i) in message.meta.images"
              >
                <img
                  v-if="image.previewImageUrl"
                  :src="image.previewImageUrl"
                  @click="showImageLightBox(getLightBoxSrcs(message), i)"
                />
                <div
                  v-if="showRecall && isSender(message) && afterCanRecallHours()"
                  class="custom-control custom-checkbox"
                >
                  <input
                    type="checkbox"
                    name="selectRecallMessage"
                    class="custom-control-input"
                    :value="image.id"
                    :id="
                      'selected_message_checkbox_' +
                      image.id +
                      image.previewImageUrl
                    "
                    @change="selected"
                    :checked="checked(image.id)"
                    :disabled="messageRecalling"
                  />
                  <label
                    class="custom-control-label"
                    :for="
                      'selected_message_checkbox_' +
                      image.id +
                      image.previewImageUrl
                    "
                  ></label>
                </div>
              </div>
            </div>
          </template>
          <div
            class="message file py-2 px-3"
            v-else-if="
              message.type === 'file' && message.content != '(訊息已收回)'
            "
          >
            <a :href="message.meta.fileUrl" target="_blank">
              <div class="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  fill="currentColor"
                >
                  <path
                    d="M374.6 310.6l-160 160C208.4 476.9 200.2 480 192 480s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 370.8V64c0-17.69 14.33-31.1 31.1-31.1S224 46.31 224 64v306.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0S387.1 298.1 374.6 310.6z"
                  />
                </svg>
              </div>
              <div class="info">
                <p>{{ message.meta.fileName }}</p>
                <p>{{ filesizeForHuman(message.meta.fileSize) }}</p>
              </div>
            </a>
          </div>
          <div
            class="message sticker py-2 px-3"
            v-else-if="message.type === 'sticker' && message.content != '(訊息已收回)'"
          >
            <img :src="message.meta.stickerUrl" />
          </div>
          <div
            class="message py-2 px-3"
            :class="message.draft ? 'draft-message' : 'message'"
            v-html="manipulateMessage(message.content)"
            v-else
          ></div>
        </div>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-1">
        <div
          v-if="
            message.type === 'image' &&
            (showMultiDownload || showThumbnailMultiDownload) &&
            message.meta.images.length > 0
          "
          class="image-download-section"
        >
          <div v-if="showMultiDownload">
            <a
              class="download-all ml-2"
              @click.prevent="downloadMultiImages(message.meta.images, 'originalContentUrl', 'file')"
              style="display: flex"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  style="width: 14px; height: 14px; margin-right: 5px"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </div>
              <div>立即下載</div>
            </a>
          </div>
          <div v-if="showThumbnailMultiDownload">
            <a
              class="download-all ml-3"
              @click.prevent="downloadMultiImages(message.meta.images, 'previewImageUrl', 'image')"
              style="display: flex"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  style="width: 14px; height: 14px; margin-right: 5px"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </div>
              <div>下載縮圖</div>
            </a>
          </div>
        </div>
        <div
          class="
            small
            text-muted
            d-flex
            align-items-end
            justify-content-end
            ml-auto
          "
        >
          <div
            v-if="
              showRecall &&
              isSender(message) &&
              message.content != '(訊息已收回)' &&
              message.type !== 'image' &&
              afterCanRecallHours()
            "
            class="mt-1 custom-control custom-checkbox"
          >
            <input
              type="checkbox"
              name="selectRecallMessage"
              class="custom-control-input"
              :value="message.id"
              :id="'selected_message_checkbox_' + message.id"
              @change="selected"
              :checked="checked(message.id)"
              :disabled="messageRecalling"
            />
            <label
              class="custom-control-label"
              :for="'selected_message_checkbox_' + message.id"
            ></label>
          </div>
          <ul class="list-unstyled read-list">
            <template v-for="sender in senders">
              <li
                :title="sender.name"
                :key="sender.id"
                v-if="senderIsRead(sender, message)"
              >
                <b-avatar size="1rem" :src="sender.avatar_url"></b-avatar>
              </li>
            </template>
          </ul>
          <time v-if="!message.draft">{{ format(new Date(message.created_at), "HH:mm") }}</time>
        </div>
      </div>
    </div>

    <ImageLightBox ref="lightbox" />
  </div>
</template>

<script>
import moment from "moment";
import format from "date-fns/format";
import chatroomMixin from "@/mixins/chatroom";
import ImageLightBox from "@/components/Page/Chatroom/ImageLightBox.vue";

export default {
  props: {
    messages: Array,
    message: Object,
    index: Number,
    showMultiDownload: {
      type: Boolean,
      default: false,
    },
    showThumbnailMultiDownload: {
      type: Boolean,
      default: false,
    },
    showRecall: {
      type: Boolean,
      default: false,
    },
    senders: Array,
    firstUnreadMessage: Object,
    canRecallHours: Number,
    selectedRecallMessages: Array,
    messageRecalling: {
      type: Boolean,
      default: false,
    },
    firstMessage: Object,
    firstLoad: Boolean,
    firstNewMessage: Object,
  },
  components: {
    ImageLightBox,
  },
  computed: {
    senderLastRead: chatroomMixin.computed.senderLastRead,
    formatDateD() {
      return this.message?.created_at
        ? moment(String(this.message.created_at)).format("YYYY-MM-DD HH:mm")
        : null;
    },
    messageSenderName() {
      return this.message.sender.nickname ?? this.message.sender.name;
    },
  },
  methods: {
    format,
    // 因為 mixin 過於耦合 ChatroomIndex，像是 beforeDestory 等等的 hook 不適合 component 使用
    // 所以把一些 Component 需要用的抽出來呼叫就好。
    manipulateMessage: chatroomMixin.methods.manipulateMessage,
    htmlspecialchars: chatroomMixin.methods.htmlspecialchars,
    parseLink: chatroomMixin.methods.parseLink,
    nl2br: chatroomMixin.methods.nl2br,
    senderIsRead: chatroomMixin.methods.senderIsRead,
    filesizeForHuman: chatroomMixin.methods.filesizeForHuman,
    isSender(message) {
      return message.sender.type === "staff";
    },
    checkStrike() {
      let messages = this.messages;
      let idx = this.index;
      if (idx == messages.length - 1) {
        return true;
      } else if (idx + 1 < messages.length) {
        let now = new Date(messages[idx + 1].created_at);
        let date = new Date(messages[idx].created_at);
        return !(
          now.getFullYear() == date.getFullYear() &&
          now.getMonth() == date.getMonth() &&
          now.getDate() == date.getDate()
        );
      } else return false;
    },
    showImageLightBox(srcs, index) {
      this.$refs.lightbox.show(srcs, index);
    },
    getLightBoxSrcs(message) {
      return message.meta.images.map((image) => image.originalContentUrl);
    },
    downloadMultiImages(images, key, type) {
      const urls = images.map((image) => image[key]);

      if (urls.length === 0) {
        this.$swal.fire({
          type: "warning",
          title: "提醒",
          text: "您尚未選擇任何圖片",
        });
        return;
      }

      // google storage 在存資料的時候分成檔案跟圖片，檔案類型直接點開網址就會下載
      // 圖片類型則是要透過 fetch 再轉成 blob 才能下載
      if (type == "image") {
        this.execMultiDownloadImage(urls);
      } else {
        this.execMultiDownload(urls);
      }
    },
    async execMultiDownload(urls) {
      var temporaryDownloadLink = document.createElement("a");
      temporaryDownloadLink.style.display = "none";

      document.body.appendChild(temporaryDownloadLink);

      for (var n = 0; n < urls.length; n++) {
        var download = urls[n];
        console.log(download);
        temporaryDownloadLink.setAttribute("href", download);
        temporaryDownloadLink.click();
        await this.sleep(500);
      }

      document.body.removeChild(temporaryDownloadLink);
    },
    async execMultiDownloadImage(urls) {
      var temporaryDownloadLink = document.createElement("a");
      temporaryDownloadLink.style.display = "none";

      document.body.appendChild(temporaryDownloadLink);

      for (var n = 0; n < urls.length; n++) {
        var download = urls[n];
        var nameOfDownload = download.split("/").pop();
        console.log(download);

        const response = await fetch(download);
        const blobImage = await response.blob();

        const href = URL.createObjectURL(blobImage);
        temporaryDownloadLink.href = href;
        temporaryDownloadLink.download = nameOfDownload;
        temporaryDownloadLink.click();

        window.URL.revokeObjectURL(href);
      }

      document.body.removeChild(temporaryDownloadLink);
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    selected(val) {
      const id = val.target.value;
      const type = this.message.type;
      this.$nextTick(() => {
        this.$emit("change", {
          id: id,
          type: type,
        });
      });
    },
    afterCanRecallHours() {
      const diffHour = (new Date() - new Date(this.message.created_at)) / 3600000
      if (diffHour < this.canRecallHours) {
        return true
      }
      return false
    },
    checked(val) {
      const type = this.message.type;
      const index = this.selectedRecallMessages.findIndex((rm) => (rm.id === val && rm.type === type));
      if (index !== -1) {
        return true
      }
      return false
    },
  },
};
</script>

<style lang="scss" scoped>
.message-wrapper {
  max-width: 80%;
  display: inline-block;

  .message {
    background-color: #f4f6f9;
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    max-width: 450px;

    ::v-deep a {
      color: inherit !important;
      text-decoration: underline;
      transition: opacity 0.15s ease-in-out;
      word-break: break-all;

      &:hover {
        opacity: 0.85;
      }
    }

    &.image {
      padding: 0 !important;
      border-radius: 0.5rem;
      overflow: hidden;
      cursor: pointer;
      display: grid;
      grid-template-columns: minmax(0, 360px);

      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
      }

      .image-wrapper {
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-top: 100%;
        position: relative;

        .custom-checkbox {
          position: absolute;
          right: 0;
          bottom: 5px;
        }
      }

      &.multiple {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 360px));
        gap: 0.5rem;
        background-color: transparent;

        .expanded {
          padding-top: 50%;
          grid-column: span 2;
        }
      }
    }

    &.file {
      border-radius: 1rem !important;

      p {
        margin: 0;
      }

      a {
        color: inherit;
        background: transparent;
        display: flex;
        align-items: center;
        text-decoration: none !important;

        .icon {
          width: 3rem;
          height: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1rem;
          background-color: rgba(255, 255, 255, 0.25);
          border-radius: 50%;
          flex-shrink: 0;

          svg {
            width: 2rem;
            height: 2rem;
            opacity: 0.75;
          }
        }
      }
    }

    &.sticker {
      background-color: transparent;

      img {
        width: 120px;
      }
    }
  }
}

.strike {
  display: flex;
  flex-direction: row;
  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #ccc;
    margin: auto 5px;
  }
}

.read-list {
  margin: 0;
  margin-right: 0.25rem;
  display: flex;
  justify-content: flex-end;
  li + li {
    margin-left: 0.125rem;
  }
}

.owner {
  align-self: flex-end;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
  .message {
    background-color: #2196f3;
    border-radius: 1rem;
    border-bottom-right-radius: 0;
    color: white;
    text-align: left;
  }
  .draft-message {
    background-color: #ACD6FF;
    border-radius: 1rem;
    border-bottom-right-radius: 0;
    color: white;
    text-align: left;
  }
}
</style>
